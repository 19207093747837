<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-select
          :value="campoParaClassificacao"
          :items="camposParaClassificacao"
          name="campoParaClassificacao"
          label="Campo para classificação dos dados"
          hint="Clique no ícone ao lado para carregar os dados para classificação"
          @change="setCampoParaClassificacao"
        >
          <v-tooltip
            slot="append-outer"
            bottom
          >
            <template v-slot:activator="{ on }">
              <span
                v-show="!loading"
                @click="getDadosUnicosPorCampo"
              >
                <v-icon
                  v-on="on"
                  color="primary"
                  dark
                  style="cursor: pointer"
                  loading
                >
                  mdi-play-speed
                </v-icon>
              </span>
              <v-progress-circular
                v-show="loading"
                indeterminate
                color="primary"
                :width="2"
                :size="25"
              />
            </template>
            <span>Clique aqui para carregar os dados para classificação</span>
          </v-tooltip>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-alert
          dense
          outlined
          type="info"
          color="blue"
          class="mb-3 py-1 px-1"
          v-if="
            visibleSecondSection && totalDadosParaClassificacao >= maximoDados
          "
        >
          Para não deixar o mapa visualmente poluído, o máximo são
          {{ maximoDados }} dados exibidos para classificação.
        </v-alert>
        <v-simple-table
          v-if="!loading"
          :style="!visibleSecondSection && 'opacity: 0.3'"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Dado igual a</th>
                <th class="text-left">
                  {{ labelColorIcon }}
                </th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(iconeCoreFeicao, dado) in iconesCoresFeicoes"
                :key="dado"
              >
                <td>{{ dado }}</td>
                <td>
                  <input-icon-color
                    label=""
                    :selectedColor="iconeCoreFeicao.cor"
                    :selectedIcon="iconeCoreFeicao.icone"
                    :displayInputIcon="tipoDadoGeografico == 'PONTO'"
                    :disabled="!visibleSecondSection"
                    @colorSelected="
                      (novaCor) => setNovaCorFeicao(dado, novaCor)
                    "
                    @iconSelected="
                      (novoIcone) => setNovoIconeFeicao(dado, novoIcone)
                    "
                  />
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        min-width="0"
                        icon
                        fab
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!visibleSecondSection"
                        @click="remover(dado)"
                      >
                        <v-icon small>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
              <tr>
                <td>Para outros dados</td>
                <td>
                  <input-icon-color
                    label=""
                    :selectedColor="iconeCorOutrasFeicoes.cor"
                    :selectedIcon="iconeCorOutrasFeicoes.icone"
                    :displayInputIcon="tipoDadoGeografico == 'PONTO'"
                    :disabled="!visibleSecondSection"
                    @colorSelected="
                      setIconeCorOutrasFeicoes({ icone: '', cor: $event })
                    "
                    @iconSelected="
                      setIconeCorOutrasFeicoes({ icone: $event, cor: '' })
                    "
                  />
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="#2B60D6"
                        min-width="0"
                        icon
                        fab
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!visibleSecondSection"
                        @click="openDialogAdicionar()"
                      >
                        <v-icon small> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <span>Adicionar</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div
          v-else
          class="d-flex justify-center align-center"
          style="height: 200px"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            :width="4"
            :size="100"
          />
        </div>
        <v-dialog
          v-model="dialog"
          max-width="350"
        >
          <v-card>
            <v-card-title class="headline"> Nova Classificação </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="dialogsData.dado"
                      label="Dado igual a"
                      class="pt-0"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <input-icon-color
                      label=""
                      :selectedColor="dialogsData.cor"
                      :selectedIcon="dialogsData.icone"
                      :displayInputIcon="tipoDadoGeografico == 'PONTO'"
                      @colorSelected="(cor) => (dialogsData.cor = cor)"
                      @iconSelected="(icone) => (dialogsData.icone = icone)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                :disabled="disabledBtnAdicionar"
                @click="adicionar()"
              >
                Adicionar
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="dialog = false"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RelatoriosService from '@/services/RelatoriosService';

const camposIgnorados = [
  'cod_id',
  'geom',
  'ais_descricao_uc',
  'ais_ei',
  'ais_subnumero',
  'descr',
  'dist',
  'lgrd'
];

const tiposIgnorados = ['decimal'];

const corPadrao = '#FF0000';

export default {
  components: {
    InputIconColor: () => import('@/components/general/InputIconColor.vue')
  },
  props: {
    tipoDadoGeografico: {
      type: String,
      required: true
    },
    campos: {
      type: Array,
      default: () => []
    },
    tiposDadosCampos: {
      type: Object,
      default: () => {}
    },
    relatorio: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      maximoDados: 10,
      dialog: false,
      dialogsData: {
        dado: '',
        icone: '',
        cor: corPadrao
      },
      loading: false
    };
  },
  computed: {
    campoParaClassificacao() {
      return this.$store.getters.getSimbologiaConfigCategorizada
        .campoParaClassificacao;
    },

    camposParaClassificacao() {
      return this.campos
        .filter((campo) => {
          return (
            !camposIgnorados.includes(campo) &&
            !tiposIgnorados.includes(this.tiposDadosCampos[campo])
          );
        })
        .sort();
    },

    iconesCoresFeicoes() {
      return this.$store.getters.getSimbologiaConfigCategorizada
        .iconesCoresFeicoes;
    },

    iconeCorOutrasFeicoes() {
      return this.$store.getters.getSimbologiaConfigCategorizada
        .iconeCorOutrasFeicoes;
    },

    totalDadosParaClassificacao() {
      return Object.keys(this.iconesCoresFeicoes).length;
    },

    disabledBtnAdicionar() {
      return !(this.dialogsData.dado !== '' && this.dialogsData.cor !== '');
    },

    visibleSecondSection() {
      return Object.keys(
        this.$store.getters.getSimbologiaConfigCategorizada.iconesCoresFeicoes
      ).length;
    },

    labelColorIcon() {
      return this.tipoDadoGeografico == 'PONTO' ? 'Ícone e cor' : 'Cor';
    }
  },
  methods: {
    getDadosUnicosPorCampo() {
      if (!this.campoParaClassificacao) {
        this.$toast.error(
          'Selecione um campo para a classificação dos dados',
          '',
          { position: 'topRight' }
        );
        return;
      }

      const relatorioId = this.relatorio.id;
      const indiceCampoCsv = this.campos.findIndex(
        (campo) => campo === this.campoParaClassificacao
      );

      if (indiceCampoCsv < 0) {
        this.$toast.error(
          'Selecione um campo para a classificação dos dados',
          '',
          { position: 'topRight' }
        );
        return;
      }

      this.loading = true;

      RelatoriosService.getDadosUnicosPorCampo(
        relatorioId,
        indiceCampoCsv,
        this.maximoDados
      )
        .then((response) => {
          let novasCoresFeicoes = {};

          response.data.forEach((dado) => {
            novasCoresFeicoes[dado] = { icone: '', cor: corPadrao };
          });

          this.setIconesCoresFeicoes(novasCoresFeicoes);
          this.setIconeCorOutrasFeicoes({ icone: '', cor: corPadrao });
        })
        .catch(() => {
          this.limparFeicoes();
          this.$toast.error(
            `Erro ao buscar os dados únicos do campo ${this.campoParaClassificacao}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },

    setCampoParaClassificacao(campo) {
      this.$store.dispatch(
        'setSimbologiaConfigCategorizadaCampoParaClassificacao',
        campo
      );
    },

    setIconesCoresFeicoes(novosIconesCoresFeicoes) {
      this.$store.dispatch(
        'setSimbologiaConfigCategorizadaIconesCoresFeicoes',
        novosIconesCoresFeicoes
      );
    },

    setIconeCorOutrasFeicoes(novoIconeCorOutrasFeicoes) {
      let aux = this.iconeCorOutrasFeicoes;

      if (novoIconeCorOutrasFeicoes.icone)
        aux.icone = novoIconeCorOutrasFeicoes.icone;
      if (typeof novoIconeCorOutrasFeicoes.icone == 'undefined') aux.icone = '';
      if (novoIconeCorOutrasFeicoes.cor)
        aux.cor = novoIconeCorOutrasFeicoes.cor;

      this.$store.dispatch(
        'setSimbologiaConfigCategorizadaIconeCorOutrasFeicoes',
        aux
      );
    },

    setNovaCorFeicao(dado, cor) {
      let iconesCoresFeicoes = { ...this.iconesCoresFeicoes };
      iconesCoresFeicoes[dado].cor = cor;
      this.setIconesCoresFeicoes(iconesCoresFeicoes);
    },

    setNovoIconeFeicao(dado, icone) {
      let iconesCoresFeicoes = { ...this.iconesCoresFeicoes };
      iconesCoresFeicoes[dado].icone = icone;
      this.setIconesCoresFeicoes(iconesCoresFeicoes);
    },

    openDialogAdicionar() {
      this.dialogsData.dado = '';
      this.dialogsData.icone = '';
      this.dialogsData.cor = corPadrao;
      this.dialog = true;
    },

    adicionar() {
      let { dado, icone, cor } = this.dialogsData;
      let iconesCoresFeicoes = { ...this.iconesCoresFeicoes };
      iconesCoresFeicoes[dado] = { icone: icone, cor: cor };
      this.setIconesCoresFeicoes(iconesCoresFeicoes);
      this.dialog = false;
    },

    remover(dado) {
      let iconesCoresFeicoes = { ...this.iconesCoresFeicoes };
      delete iconesCoresFeicoes[dado];
      this.setIconesCoresFeicoes(iconesCoresFeicoes);
    },

    limparFeicoes() {
      this.setIconesCoresFeicoes({});
      this.setIconeCorOutrasFeicoes({ icone: undefined, cor: corPadrao });
    }
  }
};
</script>
