var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"value":_vm.campoParaClassificacao,"items":_vm.camposParaClassificacao,"name":"campoParaClassificacao","label":"Campo para classificação dos dados","hint":"Clique no ícone ao lado para carregar os dados para classificação"},on:{"change":_vm.setCampoParaClassificacao}},[_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],on:{"click":_vm.getDadosUnicosPorCampo}},[_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer"},attrs:{"color":"primary","dark":"","loading":""}},on),[_vm._v(" mdi-play-speed ")])],1),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":"","color":"primary","width":2,"size":25}})]}}])},[_c('span',[_vm._v("Clique aqui para carregar os dados para classificação")])])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(
          _vm.visibleSecondSection && _vm.totalDadosParaClassificacao >= _vm.maximoDados
        )?_c('v-alert',{staticClass:"mb-3 py-1 px-1",attrs:{"dense":"","outlined":"","type":"info","color":"blue"}},[_vm._v(" Para não deixar o mapa visualmente poluído, o máximo são "+_vm._s(_vm.maximoDados)+" dados exibidos para classificação. ")]):_vm._e(),(!_vm.loading)?_c('v-simple-table',{style:(!_vm.visibleSecondSection && 'opacity: 0.3'),scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Dado igual a")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.labelColorIcon)+" ")]),_c('th',{staticClass:"text-left"},[_vm._v("Ações")])])]),_c('tbody',[_vm._l((_vm.iconesCoresFeicoes),function(iconeCoreFeicao,dado){return _c('tr',{key:dado},[_c('td',[_vm._v(_vm._s(dado))]),_c('td',[_c('input-icon-color',{attrs:{"label":"","selectedColor":iconeCoreFeicao.cor,"selectedIcon":iconeCoreFeicao.icone,"displayInputIcon":_vm.tipoDadoGeografico == 'PONTO',"disabled":!_vm.visibleSecondSection},on:{"colorSelected":function (novaCor) { return _vm.setNovaCorFeicao(dado, novaCor); },"iconSelected":function (novoIcone) { return _vm.setNovoIconeFeicao(dado, novoIcone); }}})],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","min-width":"0","icon":"","fab":"","x-small":"","disabled":!_vm.visibleSecondSection},on:{"click":function($event){return _vm.remover(dado)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Remover")])])],1)])}),_c('tr',[_c('td',[_vm._v("Para outros dados")]),_c('td',[_c('input-icon-color',{attrs:{"label":"","selectedColor":_vm.iconeCorOutrasFeicoes.cor,"selectedIcon":_vm.iconeCorOutrasFeicoes.icone,"displayInputIcon":_vm.tipoDadoGeografico == 'PONTO',"disabled":!_vm.visibleSecondSection},on:{"colorSelected":function($event){return _vm.setIconeCorOutrasFeicoes({ icone: '', cor: $event })},"iconSelected":function($event){return _vm.setIconeCorOutrasFeicoes({ icone: $event, cor: '' })}}})],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#2B60D6","min-width":"0","icon":"","fab":"","x-small":"","disabled":!_vm.visibleSecondSection},on:{"click":function($event){return _vm.openDialogAdicionar()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,389373907)},[_c('span',[_vm._v("Adicionar")])])],1)])],2)]},proxy:true}],null,false,2307823983)}):_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"200px"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","width":4,"size":100}})],1),_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Nova Classificação ")]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"label":"Dado igual a"},model:{value:(_vm.dialogsData.dado),callback:function ($$v) {_vm.$set(_vm.dialogsData, "dado", $$v)},expression:"dialogsData.dado"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('input-icon-color',{attrs:{"label":"","selectedColor":_vm.dialogsData.cor,"selectedIcon":_vm.dialogsData.icone,"displayInputIcon":_vm.tipoDadoGeografico == 'PONTO'},on:{"colorSelected":function (cor) { return (_vm.dialogsData.cor = cor); },"iconSelected":function (icone) { return (_vm.dialogsData.icone = icone); }}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","disabled":_vm.disabledBtnAdicionar},on:{"click":function($event){return _vm.adicionar()}}},[_vm._v(" Adicionar ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }